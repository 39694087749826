import React from "react";
import "./App.css";
import {
  Title,
  Medium,
  Spacer,
  BoardPreview,
  encodeBoardCharacters,
} from "@vestaboard/installables";

function App() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "rgb(24, 25, 25)",
      }}
    >
      <Title>Example Installable</Title>
      <Spacer size="small" />
      <Medium>Settings</Medium>
      <Spacer size="medium" />
      <div
        style={{
          width: "50%",
        }}
      >
        <BoardPreview
          characters={encodeBoardCharacters(
            "I Think,              Therefore I am."
          )}
        />
      </div>
    </div>
  );
}

export default App;
